import * as React from 'react';
import { Link, useTranslation} from 'gatsby-plugin-react-i18next';
import slugify from '@sindresorhus/slugify';

const ArticlesFilter = ({ data }) => {
  
  const {t} = useTranslation();

  return (
    <>
      {data.categories.group.length ?
        <div key="categories" className="mb-7">
          <div className="mb-3">
            <h3>{t('categories')}</h3>
          </div>
          {data.categories.group.map(category => (
            <Link key={category.title} to={`/articles/categories/${slugify(category.title)}/`} className="btn btn-soft-secondary btn-xs rounded-pill me-1 mb-1" activeClassName="active">
              {t('article.categories.' + category.title + '.label')} <span className="badge bg-light rounded-pill text-dark m-1">{category.totalCount}</span>
            </Link>
          ))}
        </div>
      : null} 
      {data.tags.group.length ?
        <div key="tags" className="mb-7">
          <div className="mb-3">
            <h3>{t('tags')}</h3>
          </div>
          {data.tags.group.map(tag => (
            <Link key={tag.title} to={`/articles/tags/${slugify(tag.title)}/`} className="btn btn-soft-secondary btn-xs rounded-pill me-1 mb-1" activeClassName="active">
              {t('article.tags.' + tag.title + '.label')} <span className="badge bg-light rounded-pill text-dark m-1">{tag.totalCount}</span>
            </Link>
          ))}
        </div>
      : null}
    </>
  )
}

export default ArticlesFilter